import "./FormTooltip.scss";

import {useEffect, useRef, useState} from "react"
import {createPortal} from "react-dom"

import IconInfoCircle from "../../Icon/IconInfoCircle"

export default function FormTooltip({children, content}) {
  const formTooltipRef = useRef(null)
  const [tooltipIsLoaded, setTooltipIsLoaded] = useState(false)
  const [messageIsDisplayed, canDisplayMessage] = useState(false)

  useEffect(() => {
    if (formTooltipRef.current) {
      setTooltipIsLoaded(true)
    }
  }, [formTooltipRef]);

  return (
    <div className={"FormTooltip"} ref={formTooltipRef}>
      {children}
      {tooltipIsLoaded && createPortal(
        <span
          className={"FormTooltip-icon"}
          onMouseEnter={() => window.innerWidth > 768 && canDisplayMessage(true)}
          onMouseLeave={() => window.innerWidth > 768 && canDisplayMessage(false)}
          onClick={() => window.innerWidth <= 768 && canDisplayMessage(!messageIsDisplayed)}
        >
          <IconInfoCircle size={"16"}/>
          {messageIsDisplayed && (
            <div className={"FormTooltip-messageContainer"}>
              <p className={"FormTooltip-message tag-p is-m"}>
                {content}
              </p>
            </div>
          )}
        </span>,
        formTooltipRef.current.querySelector(".tag-label")
      )}
    </div>
  )
}