import "./CandidaturesSinglePageSavoirEtreProfessionnel.scss"

import {useState} from "react"

import FormInputRadioGroup from "../../../Form/InputRadio/Group/FormInputRadioGroup"
import FormInputRadio from "../../../Form/InputRadio/FormInputRadio"
import {scrollToFirstError} from "../../helpers"

export default function CandidaturesSinglePageSavoirEtreProfessionnel(
  {
    SavoirEtreProfessionnel,
    onSavoirEtreProfessionnelChange,
  }
) {
  const [PrendreLaParoleEnPublic, setPrendreLaParoleEnPublic] = useState(SavoirEtreProfessionnel.PrendreLaParoleEnPublic)
  const [Negocier, setNegocier] = useState(SavoirEtreProfessionnel.Negocier)
  const [EtreALecouteDeMesInterlocuteurs, setEtreALecouteDeMesInterlocuteurs] = useState(SavoirEtreProfessionnel.EtreALecouteDeMesInterlocuteurs)
  const [Convaincre, setConvaincre] = useState(SavoirEtreProfessionnel.Convaincre)
  const [MadapterAMonEnvironnement, setMadapterAMonEnvironnement] = useState(SavoirEtreProfessionnel.MadapterAMonEnvironnement)
  const [AnalyserUneSituationOuUnProbleme, setAnalyserUneSituationOuUnProbleme] = useState(SavoirEtreProfessionnel.AnalyserUneSituationOuUnProbleme)
  const [GererDesConflits, setGererDesConflits] = useState(SavoirEtreProfessionnel.GererDesConflits)
  const [TravaillerEnEquipe, setTravaillerEnEquipe] = useState(SavoirEtreProfessionnel.TravaillerEnEquipe)
  const [AvoirUnRoleDeFacilitateur, setAvoirUnRoleDeFacilitateur] = useState(SavoirEtreProfessionnel.AvoirUnRoleDeFacilitateur)
  const [Federer, setFederer] = useState(SavoirEtreProfessionnel.Federer)
  const [EtreRigoureux, setEtreRigoureux] = useState(SavoirEtreProfessionnel.EtreRigoureux)
  const [MobiliserSaCreativite, setMobiliserSaCreativite] = useState(SavoirEtreProfessionnel.MobiliserSaCreativite)

  const submit = (event) => {
    event.preventDefault()

    if(!event.target.checkValidity()) {
      scrollToFirstError(event.target)
      return
    }

    onSavoirEtreProfessionnelChange({
      PrendreLaParoleEnPublic,
      Negocier,
      EtreALecouteDeMesInterlocuteurs,
      Convaincre,
      MadapterAMonEnvironnement,
      AnalyserUneSituationOuUnProbleme,
      GererDesConflits,
      TravaillerEnEquipe,
      AvoirUnRoleDeFacilitateur,
      Federer,
      EtreRigoureux,
      MobiliserSaCreativite,
    })
  }

  return (
    <form className={"CandidaturesSinglePageSavoirEtreProfessionnel"} onSubmit={submit} noValidate>
      <div className={"helpers-formBody"}>
        <p className={"tag-p is-m"}>
          Afin de mieux vous connaître nous aimerions savoir quelles sont vos points forts et
          vos points d’amélioration avant d’entrer dans l’école.
        </p>
        <div className={"CandidaturesSinglePageSavoirEtreProfessionnel-inner"}>
          <FormInputRadioGroup label={"Prendre la parole en public"} required={true}>
            <FormInputRadio
              checked={PrendreLaParoleEnPublic === true}
              id={"PrendreLaParoleEnPublicOui"}
              label={"Point fort"}
              name={"PrendreLaParoleEnPublic"}
              onChange={() => setPrendreLaParoleEnPublic(true)}
              required={true}
              value={"1"}
            />
            <FormInputRadio
              checked={PrendreLaParoleEnPublic === false}
              id={"PrendreLaParoleEnPublicNon"}
              label={"Axe d'amélioration"}
              name={"PrendreLaParoleEnPublic"}
              onChange={() => setPrendreLaParoleEnPublic(false)}
              required={true}
              value={"0"}
            />
          </FormInputRadioGroup>

          <FormInputRadioGroup label={"Négocier"} required={true}>
            <FormInputRadio
              checked={Negocier === true}
              id={"NegocierOui"}
              label={"Point fort"}
              name={"Negocier"}
              onChange={() => setNegocier(true)}
              required={true}
              value={"1"}
            />
            <FormInputRadio
              checked={Negocier === false}
              id={"NegocierNon"}
              label={"Axe d'amélioration"}
              name={"Negocier"}
              onChange={() => setNegocier(false)}
              required={true}
              value={"0"}
            />
          </FormInputRadioGroup>

          <FormInputRadioGroup label={"Être à l’écoute de mes interlocuteurs"} required={true}>
            <FormInputRadio
              checked={EtreALecouteDeMesInterlocuteurs === true}
              id={"EtreALecouteDeMesInterlocuteursOui"}
              label={"Point fort"}
              name={"EtreALecouteDeMesInterlocuteurs"}
              onChange={() => setEtreALecouteDeMesInterlocuteurs(true)}
              required={true}
              value={"1"}
            />
            <FormInputRadio
              checked={EtreALecouteDeMesInterlocuteurs === false}
              id={"EtreALecouteDeMesInterlocuteursNon"}
              label={"Axe d'amélioration"}
              name={"EtreALecouteDeMesInterlocuteurs"}
              onChange={() => setEtreALecouteDeMesInterlocuteurs(false)}
              required={true}
              value={"0"}
            />
          </FormInputRadioGroup>

          <FormInputRadioGroup label={"Convaincre"} required={true}>
            <FormInputRadio
              checked={Convaincre === true}
              id={"ConvaincreOui"}
              label={"Point fort"}
              name={"Convaincre"}
              onChange={() => setConvaincre(true)}
              required={true}
              value={"1"}
            />
            <FormInputRadio
              checked={Convaincre === false}
              id={"ConvaincreNon"}
              label={"Axe d'amélioration"}
              name={"Convaincre"}
              onChange={() => setConvaincre(false)}
              required={true}
              value={"0"}
            />
          </FormInputRadioGroup>

          <FormInputRadioGroup label={"M’adapter à mon environnement"} required={true}>
            <FormInputRadio
              checked={MadapterAMonEnvironnement === true}
              id={"MadapterAMonEnvironnementOui"}
              label={"Point fort"}
              name={"MadapterAMonEnvironnement"}
              onChange={() => setMadapterAMonEnvironnement(true)}
              required={true}
              value={"1"}
            />
            <FormInputRadio
              checked={MadapterAMonEnvironnement === false}
              id={"MadapterAMonEnvironnementNon"}
              label={"Axe d'amélioration"}
              name={"MadapterAMonEnvironnement"}
              onChange={() => setMadapterAMonEnvironnement(false)}
              required={true}
              value={"0"}
            />
          </FormInputRadioGroup>

          <FormInputRadioGroup label={"Analyser une situation ou un problème"} required={true}>
            <FormInputRadio
              checked={AnalyserUneSituationOuUnProbleme === true}
              id={"AnalyserUneSituationOuUnProblemeOui"}
              label={"Point fort"}
              name={"AnalyserUneSituationOuUnProbleme"}
              onChange={() => setAnalyserUneSituationOuUnProbleme(true)}
              required={true}
              value={"1"}
            />
            <FormInputRadio
              checked={AnalyserUneSituationOuUnProbleme === false}
              id={"AnalyserUneSituationOuUnProblemeNon"}
              label={"Axe d'amélioration"}
              name={"AnalyserUneSituationOuUnProbleme"}
              onChange={() => setAnalyserUneSituationOuUnProbleme(false)}
              required={true}
              value={"0"}
            />
          </FormInputRadioGroup>

          <FormInputRadioGroup label={"Gérer les conflits"} required={true}>
            <FormInputRadio
              checked={GererDesConflits === true}
              id={"GererDesConflitsOui"}
              label={"Point fort"}
              name={"GererDesConflits"}
              onChange={() => setGererDesConflits(true)}
              required={true}
              value={"1"}
            />
            <FormInputRadio
              checked={GererDesConflits === false}
              id={"GererDesConflitsNon"}
              label={"Axe d'amélioration"}
              name={"GererDesConflits"}
              onChange={() => setGererDesConflits(false)}
              required={true}
              value={"0"}
            />
          </FormInputRadioGroup>

          <FormInputRadioGroup label={"Travailler en équipe"} required={true}>
            <FormInputRadio
              checked={TravaillerEnEquipe === true}
              id={"TravaillerEnEquipeOui"}
              label={"Point fort"}
              name={"TravaillerEnEquipe"}
              onChange={() => setTravaillerEnEquipe(true)}
              required={true}
              value={"1"}
            />
            <FormInputRadio
              checked={TravaillerEnEquipe === false}
              id={"TravaillerEnEquipeNon"}
              label={"Axe d'amélioration"}
              name={"TravaillerEnEquipe"}
              onChange={() => setTravaillerEnEquipe(false)}
              required={true}
              value={"0"}
            />
          </FormInputRadioGroup>

          <FormInputRadioGroup label={"Avoir un rôle facilitateur"} required={true}>
            <FormInputRadio
              checked={AvoirUnRoleDeFacilitateur === true}
              id={"AvoirUnRoleDeFacilitateurOui"}
              label={"Point fort"}
              name={"AvoirUnRoleDeFacilitateur"}
              onChange={() => setAvoirUnRoleDeFacilitateur(true)}
              required={true}
              value={"1"}
            />
            <FormInputRadio
              checked={AvoirUnRoleDeFacilitateur === false}
              id={"AvoirUnRoleDeFacilitateurNon"}
              label={"Axe d'amélioration"}
              name={"AvoirUnRoleDeFacilitateur"}
              onChange={() => setAvoirUnRoleDeFacilitateur(false)}
              required={true}
              value={"0"}
            />
          </FormInputRadioGroup>

          <FormInputRadioGroup label={"Fédérer"} required={true}>
            <FormInputRadio
              checked={Federer === true}
              id={"FedererOui"}
              label={"Point fort"}
              name={"Federer"}
              onChange={() => setFederer(true)}
              required={true}
              value={"1"}
            />
            <FormInputRadio
              checked={Federer === false}
              id={"FedererNon"}
              label={"Axe d'amélioration"}
              name={"Federer"}
              onChange={() => setFederer(false)}
              required={true}
              value={"0"}
            />
          </FormInputRadioGroup>

          <FormInputRadioGroup label={"Être rigoureux"} required={true}>
            <FormInputRadio
              checked={EtreRigoureux === true}
              id={"EtreRigoureuxOui"}
              label={"Point fort"}
              name={"EtreRigoureux"}
              onChange={() => setEtreRigoureux(true)}
              required={true}
              value={"1"}
            />
            <FormInputRadio
              checked={EtreRigoureux === false}
              id={"EtreRigoureuxNon"}
              label={"Axe d'amélioration"}
              name={"EtreRigoureux"}
              onChange={() => setEtreRigoureux(false)}
              required={true}
              value={"0"}
            />
          </FormInputRadioGroup>

          <FormInputRadioGroup label={"Mobiliser sa créativité"} required={true}>
            <FormInputRadio
              checked={MobiliserSaCreativite === true}
              id={"MobiliserSaCreativiteOui"}
              label={"Point fort"}
              name={"MobiliserSaCreativite"}
              onChange={() => setMobiliserSaCreativite(true)}
              required={true}
              value={"1"}
            />
            <FormInputRadio
              checked={MobiliserSaCreativite === false}
              id={"MobiliserSaCreativiteNon"}
              label={"Axe d'amélioration"}
              name={"MobiliserSaCreativite"}
              onChange={() => setMobiliserSaCreativite(false)}
              required={true}
              value={"0"}
            />
          </FormInputRadioGroup>
        </div>
      </div>
      <div className={"helpers-formFooter"}>
        <button
          aria-label={"Continuer"}
          className={"helpers-buttonSubmit tag-button"}
          title={"Continuer"}
          type={"submit"}
        >
          Continuer
        </button>
      </div>
    </form>
  )
}