import "./FormInputCheckbox.scss"

import {useState} from "react"
import classNames from "classnames";

export default function FormInputCheckbox(
  {
    checked,
    id,
    invalid,
    label,
    name,
    required,
    value,
  }
) {
  const [isInvalid, setIsInvalid] = useState(invalid)
  const [validationMessage, setValidationMessage] = useState(null)

  return (
    <label className="FormInputCheckbox" htmlFor={id}>
      <input
        checked={checked}
        id={id}
        name={name}
        onClick={() => {
          setIsInvalid(false)
        }}
        onInvalid={(e) => {
          setValidationMessage(e.target.validationMessage)
          setIsInvalid(true)
        }}
        required={required}
        type="checkbox"
        value={value}
      />
      {isInvalid && (
        <span className={"FormInput-error"}>
          <p className={"FormInput-errorMessage tag-p is-s"}>{validationMessage}</p>
        </span>
      )}
      <span className={classNames("FormInputCheckbox-inner", {
        "is-invalid": isInvalid,
      })}>
        {label}
      </span>
    </label>
  )
}