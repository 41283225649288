import "./CandidaturesSinglePageProjetProfessionnel.scss"

import {useState} from "react"

import FormTextarea from "../../../Form/FormTextarea"
import {scrollToFirstError} from "../../helpers"

export default function CandidaturesSinglePageProjetProfessionnel({ProjetProfessionnel, onProjetProfessionnel}) {
  const [Description, setDescription] = useState(ProjetProfessionnel.Description)

  const submit = (event) => {
    event.preventDefault()

    if(!event.target.checkValidity()) {
      scrollToFirstError(event.target)
      return
    }

    onProjetProfessionnel({
      Description,
    })
  }

  return (
    <form className={"CandidaturesSinglePageProjetProfessionnel"} onSubmit={submit} noValidate>
      <div className={"helpers-formBody"}>
        <FormTextarea
          label={"Décrivez en quelques lignes votre projet professionnel à l’issu de votre formation"}
          name={"Description"}
          onChange={(e) => setDescription(e.target.value)}
          required={true}
          value={Description}
        />
      </div>
      <div className="helpers-formFooter">
        <button
          aria-label={"Continuer"}
          className={"helpers-buttonSubmit tag-button"}
          type={"submit"}
          title={"Continuer"}
        >
          Continuer
        </button>
      </div>
    </form>
  )
}