import "./CandidaturesSinglePageEcole.scss"

import {useState} from "react"
import FormTextarea from "../../../Form/FormTextarea"
import {scrollToFirstError} from "../../helpers";

export default function CandidaturesSinglePageEcole({Ecole, onEcoleChange}) {
  const [CeQueJattendsDeLecole, setCeQueJattendsDeLecole] = useState(Ecole.CeQueJattendsDeLecole)
  const [
    LesFormationsEtEcolesQueJaiCompareAvantDeFaireMonChoix,
    setLesFormationsEtEcolesQueJaiCompareAvantDeFaireMonChoix,
  ] = useState(Ecole.LesFormationsEtEcolesQueJaiCompareAvantDeFaireMonChoix)

  const submit = (event) => {
    event.preventDefault()

    if(!event.target.checkValidity()) {
      scrollToFirstError(event.target)
      return
    }

    onEcoleChange({
      CeQueJattendsDeLecole,
      LesFormationsEtEcolesQueJaiCompareAvantDeFaireMonChoix,
    })
  }

  return (
    <form className={"CandidaturesSinglePageEcole"} onSubmit={submit} noValidate>
      <div className={"helpers-formBody"}>
        <FormTextarea
          label={"Comment avez-vous découvert HETIC et pourquoi avez-vous décidé de candidater dans cette école particulièrement ?"}
          name={"CeQueJattendsDeLecole"}
          onChange={(e) => setCeQueJattendsDeLecole(e.target.value)}
          required={true}
          value={CeQueJattendsDeLecole}
        />
        <FormTextarea
          label={
            <>
              Avez vous comparé des écoles avant de faire votre choix ?
              <span className={"helpers-ifYes"}>
                Si oui, listez les ci-dessous
              </span>
            </>
          }
          name={"LesFormationsEtEcolesQueJaiCompareAvantDeFaireMonChoix"}
          onChange={(e) => setLesFormationsEtEcolesQueJaiCompareAvantDeFaireMonChoix(e.target.value)}
          value={LesFormationsEtEcolesQueJaiCompareAvantDeFaireMonChoix}
        />
      </div>
      <div className={"helpers-formFooter"}>
        <button
          aria-label={"Continuer"}
          className={"helpers-buttonSubmit tag-button"}
          type={"submit"}
          title={"Continuer"}
        >
          Continuer
        </button>
      </div>
    </form>
  )
}