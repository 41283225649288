import {useEffect, useState} from "react"
import countries from "i18n-iso-countries"

import FormInputHead from "./InputHead/FormInputHead"

export default function FormSelectCountry(
  {
    description,
    disabled,
    invalid,
    label,
    name,
    onChange,
    placeholder,
    required = false,
    title,
    value,
  }
) {
  countries.registerLocale(require("i18n-iso-countries/langs/fr.json"))
  const [countriesOptions, setCountriesOptions] = useState(null)
  const [isInvalid, setIsInvalid] = useState(invalid)
  const [validationMessage, setValidationMessage] = useState(null)

  useEffect(() => {
    const countryNames = countries.getNames("fr", {select: "official"})

    setCountriesOptions(sortCountriesByAlphabetical(countryNames))
  }, [])

  const sortCountriesByAlphabetical = (countries) => {
    const keys = Object.keys(countries)
    const values = Object.values(countries)

    const sortedCountries = values.map((valeur, index) => {
      return {
        key: keys[index],
        value: valeur,
      }
    })

    sortedCountries.sort((a, b) => a.value.localeCompare(b.value))

    return sortedCountries
  }

  return (
    <div className={`FormInput ${disabled ? "is-disabled" : ""}`}>
      <FormInputHead
        description={description}
        label={label}
        name={name}
        required={required}
      />
      {isInvalid && (
        <span className={"FormInput-error"}>
          <p className={"FormInput-errorMessage tag-p is-s"}>{title ? title : validationMessage}</p>
        </span>
      )}
      <select
        className={`tag-select ${isInvalid ? "has-error" : ""}`}
        disabled={disabled}
        id={name}
        name={name}
        onChange={e => {
          setValidationMessage(null)
          setIsInvalid(false)
          onChange(e)
        }}
        onInvalid={(e) => {
          setValidationMessage(e.target.validationMessage)
          setIsInvalid(true)
        }}
        value={value}
        required={required}
      >
        {placeholder && (
          <option value={""} disabled>{placeholder}</option>
        )}
        {countriesOptions && countriesOptions.map((option, i) => (
          <option key={i} value={option.value}>{option.value}</option>
        ))}
      </select>
    </div>
  )
}