import "./FormInputHead.scss";

export default function FormInputHead(
  {
    description,
    label,
    name,
    required,
  }
) {
  return (
    <div className={"FormInputHead"}>
      <div className={`tag-label ${required ? ("is-required") : ""}`}>
        <label htmlFor={name}>{label}{required ? (
          <span className={"FormInputHead-required"}>*</span>
        ) : ""}</label>
      </div>
      {description && (
        <div className="FormInputHead-description">{description}</div>
      )}
    </div>
  )
}