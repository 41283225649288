import "./CandidaturesSinglePageTuteurs.scss"

import {useState} from "react"

import CandidaturesSinglePageTuteursQuestion from "./Question/CandidaturesSinglePageTuteursQuestion"
import CandidaturesSinglePageTuteursForm from "./Form/CandidaturesSinglePageTuteursForm"
import {getAgeFromBirthDate} from "./helpers"

export default function CandidaturesSinglePageTuteurs({EtatCivil, Tuteurs, onTuteursChange}) {
  const [hasTuteurs, setHasTuteurs] = useState(Tuteurs !== null)
  const [isMineur] = useState(getAgeFromBirthDate(EtatCivil.DateDeNaissance) < 18)

  const onFormTuteursChange = (Tuteurs) => {
    if (Tuteurs.length === 0) {
      setHasTuteurs(false)
      return
    }

    onTuteursChange(Tuteurs)
  }

  return (
    <div className={"CandidaturesSinglePageTuteurs"}>
      <CandidaturesSinglePageTuteursQuestion
        hasTuteurs={hasTuteurs}
        isMineur={isMineur}
        onHasTuteursChange={setHasTuteurs}
      />
      {(!hasTuteurs && !isMineur) && (
        <div className={"helpers-formFooter"}>
          <button
            aria-label={"Continuer"}
            className={"helpers-buttonSubmit tag-button"}
            onClick={() => onTuteursChange(null)}
            title={"Continuer"}
          >
            Continuer
          </button>
        </div>
      )}
      {(hasTuteurs || isMineur) && (
        <CandidaturesSinglePageTuteursForm
          Tuteurs={(hasTuteurs && Tuteurs) ? Tuteurs : []}
          isMineur={isMineur}
          onTuteursChange={onFormTuteursChange}
        />
      )}
    </div>
  )
}