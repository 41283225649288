import axios from "axios"

export const api = (method, uri, data) => {
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: process.env.REACT_APP_HETIC_API_URL + uri,
      data,
    })
      .then(({data}) => resolve(data))
      .catch(reject)
  })
}