import "./FormInput.scss"

import {useState} from "react"

import FormInputHead from "./InputHead/FormInputHead"

export default function FormInputEmail(
  {
    description,
    disabled,
    invalid,
    label,
    name,
    onChange,
    required,
    value,
  }
) {
  const [isInvalid, setIsInvalid] = useState(invalid)

  return (
    <div className={`FormInput ${disabled ? "is-disabled" : ""}`}>
      <FormInputHead
        description={description}
        label={label}
        name={name}
        required={required}
      />
      {isInvalid && (
        <span className={"FormInput-error"}>
          <p className={"FormInput-errorMessage tag-p is-s"}>L'adresse e-mail n'est pas au format valide.</p>
        </span>
      )}
      <input
        className={`tag-input ${isInvalid ? "has-error" : ""}`}
        disabled={disabled}
        id={name}
        name={name}
        onChange={e => {
          setIsInvalid(false)
          onChange(e)
        }}
        onInvalid={() => {
          setIsInvalid(true)
        }}
        placeholder={"ex: john.doe@gmail.com"}
        required={required}
        type="email"
        pattern="^(?=.*[@])(?=.*[.]).*$"
        value={value}
      />
    </div>
  )
}