export default function IconChevronRight({size}) {
  return (
    <svg
      fill="none"
      width={size ? size : "24"}
      height={size ? size : "24"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path clipRule="evenodd"
            d="m8.29289 5.29289c.39053-.39052 1.02369-.39052 1.41422 0l5.99999 6.00001c.3905.3905.3905 1.0237 0 1.4142l-5.99999 6c-.39053.3905-1.02369.3905-1.41422 0-.39052-.3905-.39052-1.0237 0-1.4142l5.29291-5.2929-5.29291-5.29289c-.39052-.39053-.39052-1.02369 0-1.41422z"
            fill="currentColor" fillRule="evenodd"/>
    </svg>
  )
}