import "../FormInput.scss"
import "./FormInputDate.scss"

import {useState} from "react"

import FormInputHead from "../InputHead/FormInputHead"

export default function FormInputDate(
  {
    description,
    disabled,
    invalid,
    label,
    name,
    onChange,
    required,
    title,
    value,
  }
) {
  const [isInvalid, setIsInvalid] = useState(invalid)
  const [validationMessage, setValidationMessage] = useState(null)

  const formatDate = (inputDate) => {
    const [year, month, day] = inputDate.split('-')
    return `${day}/${month}/${year}`
  }

  const parseDate = (inputDate) => {
    const [day, month, year] = inputDate.split('/')
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  }

  return (
    <div className={`FormInputDate FormInput ${disabled ? "is-disabled" : ""}`}>
      <FormInputHead
        description={description}
        label={label}
        name={name}
        required={required}
      />
      {isInvalid && (
        <span className={"FormInput-error"}>
          <p className={"FormInput-errorMessage tag-p is-s"}>{title ? title : validationMessage}</p>
        </span>
      )}
      <input
        className={`tag-input ${isInvalid ? "has-error" : ""}`}
        disabled={disabled}
        id={name}
        name={name}
        onChange={e => {
          setValidationMessage(null)
          setIsInvalid(false)
          onChange(formatDate(e.target.value))
        }}
        onInvalid={(e) => {
          setValidationMessage(e.target.validationMessage)
          setIsInvalid(true)
        }}
        placeholder={"jj/mm/yyyy"}
        required={required}
        type={"date"}
        value={value !== "" ? parseDate(value) : ""}
      />
    </div>
  )
}