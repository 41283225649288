import "./FormInput.scss"

import {useState} from "react"

import FormInputHead from "./InputHead/FormInputHead"

export default function FormInputNumber(
  {
    description,
    disabled,
    invalid,
    label,
    name,
    onChange,
    pattern,
    placeholder,
    required,
    title,
    value,
  }
) {
  const [isInvalid, setIsInvalid] = useState(invalid)
  const [validationMessage, setValidationMessage] = useState(null)

  return (
    <div className={`FormInput ${disabled ? "is-disabled" : ""}`}>
      <FormInputHead
        description={description}
        label={label}
        name={name}
        required={required}
      />
      {isInvalid && (
        <span className={"FormInput-error"}>
          <p className={"FormInput-errorMessage tag-p is-s"}>{title ? title : validationMessage}</p>
        </span>
      )}
      <input
        className={`tag-input ${isInvalid ? "has-error" : ""}`}
        disabled={disabled}
        id={name}
        name={name}
        onChange={e => {
          setValidationMessage(null)
          setIsInvalid(false)
          onChange(e)
        }}
        onInvalid={(e) => {
          setValidationMessage(e.target.validationMessage)
          setIsInvalid(true)
        }}
        pattern={pattern}
        placeholder={placeholder}
        required={required}
        type="number"
        title={title}
        value={value}
      />
    </div>
  );
}