import "react-phone-input-2/lib/style.css"

import "../FormInput.scss"
import "./FormInputTel.scss"

import {useRef, useState} from "react"
import PhoneInput from "react-phone-input-2"
import fr from "react-phone-input-2/lang/fr.json"

import FormInputHead from "../InputHead/FormInputHead"
import {isValidInternationalPhoneNumber} from "../../helpers"

export default function FormInputTel(
  {
    disabled,
    label,
    name,
    onChange,
    required,
    value,
  }
) {
  const ref = useRef(null)
  const [isValid, setIsValid] = useState(true)
  const [validationMessage, setValidationMessage] = useState(null)

  return (
    <div className={`FormInput ${disabled ? "is-disabled" : ""}`}>
      <FormInputHead
        label={label}
        name={name}
        required={required}
      />
      {!isValid && (
        <span className={"FormInput-error"}>
          <p className={"FormInput-errorMessage tag-p is-s"}>{validationMessage}</p>
        </span>
      )}
      <PhoneInput
        containerClass={"FormInputTel"}
        country={"fr"}
        countryCodeEditable={false}
        disableSearchIcon={true}
        enableSearch={true}
        inputClass={`tag-input ${!isValid ? "has-error" : ""}`}
        inputProps={{
          required: required,
          onInvalid: (e) => {
            setValidationMessage(e.target.validationMessage)
            setIsValid(false)
          },
          ref: ref,
        }}
        isValid={(value) => {
          if(!isValidInternationalPhoneNumber(`+${value}`)) {
            ref.current && ref.current.setCustomValidity("Le numéro n'est pas au format valide.")
          } else {
            ref.current && ref.current.setCustomValidity("")
            setIsValid(true)
          }
        }}
        localization={fr}
        onChange={(phone) => {
          onChange(`+${phone}`)
        }}
        placeholder={"ex: 1 76 36 10 07"}
        preferredCountries={[
          "fr",
          "gb",
          "es",
          "it",
          "de",
          "ma",
          "dz",
          "tn",
          "tr",
          "bj",
          "ci",
          "tg",
          "sn",
        ]}
        preserveOrder={["preferredCountries"]}
        searchPlaceholder={"Rechercher"}
        value={value}
      />
    </div>
  )
}