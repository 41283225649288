export default function IconEdit({size}) {
  return (
    <svg
      fill="none"
      width={size ? size : "24"}
      height={size ? size : "24"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M16.2929 2.29289C17.788 0.797796 20.212 0.797798 21.7071 2.29289C23.2022 3.78799 23.2022 6.21201 21.7071 7.70711L8.50081 20.9134C8.48405 20.9301 8.46744 20.9468 8.45096 20.9633C8.20606 21.2086 7.99004 21.4249 7.73337 21.5942C7.50771 21.743 7.26298 21.8606 7.00581 21.9439C6.7133 22.0386 6.40939 22.0721 6.06486 22.1101C6.04168 22.1126 6.01832 22.1152 5.99478 22.1178L2.61041 22.4939C2.30848 22.5274 2.00768 22.4219 1.79287 22.2071C1.57806 21.9923 1.47255 21.6915 1.50609 21.3896L1.88213 18.0052C1.88475 17.9816 1.88733 17.9583 1.88989 17.9351C1.92789 17.5906 1.96141 17.2867 2.0561 16.9942C2.13935 16.737 2.25698 16.4923 2.40579 16.2666C2.57504 16.0099 2.79141 15.7939 3.0367 15.549C3.0532 15.5325 3.06984 15.5159 3.0866 15.4992L16.2929 2.29289ZM20.2929 3.7071C19.5788 2.99306 18.4212 2.99306 17.7071 3.7071L4.50081 16.9134C4.17831 17.2359 4.11843 17.3024 4.07544 17.3676C4.02584 17.4428 3.98663 17.5244 3.95888 17.6101C3.93483 17.6844 3.92027 17.7728 3.8699 18.2261L3.6319 20.3681L5.77391 20.1301C6.22721 20.0797 6.31553 20.0651 6.38983 20.0411C6.47556 20.0133 6.55714 19.9741 6.63235 19.9245C6.69755 19.8815 6.76409 19.8217 7.0866 19.4992L20.2929 6.29289C21.0069 5.57885 21.0069 4.42115 20.2929 3.7071Z"
            fill="currentColor"/>
    </svg>
  )
}