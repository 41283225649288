import "./CandidaturesSinglePageTuteursForm.scss"

import {useEffect, useState} from "react"
import classNames from "classnames"

import FormFieldset from "../../../../Form/Fieldset/FormFieldset"
import FormInputText from "../../../../Form/FormInputText"
import FormInputTel from "../../../../Form/InputTel/FormInputTel"
import IconTrash from "../../../../Icon/IconTrash"
import IconPlus from "../../../../Icon/IconPlus"
import FormSelectCountry from "../../../../Form/FormSelectCountry"
import {scrollToFirstError} from "../../../helpers"

export default function CandidaturesSinglePageTuteursForm({Tuteurs, isMineur, onTuteursChange}) {
  const [localTuteurs, setLocalTuteurs] = useState(Tuteurs)

  useEffect(() => {
    if (localTuteurs.length === 0) {
      pushEmptyTuteur()
    }
  }, [])

  const pushEmptyTuteur = () => {
    setLocalTuteurs(Tuteurs => [...Tuteurs, {
      Adresse: {
        Adresse: "",
        CodePostal: "",
        Pays: "",
        Ville: "",
      },
      Nom: "",
      Prenom: "",
      Telephone: "",
    }])
  }

  const deleteTuteur = (tuteurIndex) => {
    if (localTuteurs.length === 1) {
      onTuteursChange([])
    }

    setLocalTuteurs([
      ...localTuteurs.slice(0, tuteurIndex),
      ...localTuteurs.slice(tuteurIndex + 1),
    ])
  }

  const updateTuteur = (tuteurIndex, data) => {
    setLocalTuteurs(tuteurs => {
      tuteurs[tuteurIndex] = data
      return [...tuteurs]
    })
  }

  const submit = (event) => {
    event.preventDefault()

    if (!event.target.checkValidity()) {
      scrollToFirstError(event.target)
      return
    }

    onTuteursChange(localTuteurs)
  }

  return (
    <form className={"CandidaturesSinglePageTuteursForm"} onSubmit={submit} noValidate>
      <div className="helpers-formBody">
        {localTuteurs.length > 0 && localTuteurs.map((tuteur, tuteurIndex) => (
          <div className={"CandidaturesSinglePageTuteursForm-tuteur"} key={tuteurIndex}>
            <FormFieldset label={`Tuteur ${tuteurIndex + 1}`}>
              <button
                aria-label={"Supprimer le tuteur"}
                className={classNames("CandidaturesSinglePageTuteursForm-buttonRemove", {
                  "helpers-display-n": isMineur && tuteurIndex === 0,
                })}
                onClick={() => deleteTuteur(tuteurIndex)}
                title={"Supprimer le tuteur"}
                type={"button"}
              >
                <IconTrash size={"20"}/>
              </button>
              <FormInputText
                label={"Nom"}
                name={`Nom[]`}
                onChange={(e) => updateTuteur(tuteurIndex, {
                  ...tuteur,
                  Nom: e.target.value,
                })}
                placeholder={"ex: Doe"}
                required={true}
                value={tuteur.Nom}
              />
              <FormInputText
                label={"Prénom"}
                name={`Prenom[]`}
                onChange={(e) => updateTuteur(tuteurIndex, {
                  ...tuteur,
                  Prenom: e.target.value,
                })}
                placeholder={"ex: John"}
                required={true}
                value={tuteur.Prenom}
              />
              <FormInputTel
                label={"Téléphone"}
                name={`Telephone[]`}
                onChange={(value) => updateTuteur(tuteurIndex, {
                  ...tuteur,
                  Telephone: value,
                })}
                required={true}
                value={tuteur.Telephone}
              />
              <FormFieldset label={"Adresse"}>
                <FormInputText
                  label={"Numéro et nom de la rue"}
                  name={`Adresse[Adresse][]`}
                  onChange={(e) => updateTuteur(tuteurIndex, {
                    ...tuteur,
                    Adresse: {
                      ...tuteur.Adresse,
                      Adresse: e.target.value,
                    }
                  })}
                  placeholder={"ex: 12 rue des tulipes"}
                  required={true}
                  value={tuteur.Adresse.Adresse}
                />
                <div className={"helpers-dualInputs"}>
                  <FormInputText
                    label={"Code Postal"}
                    name={`Adresse[CodePostal][]`}
                    onChange={(e) => updateTuteur(tuteurIndex, {
                      ...tuteur,
                      Adresse: {
                        ...tuteur.Adresse,
                        CodePostal: e.target.value,
                      }
                    })}
                    placeholder={"ex: 75010"}
                    required={true}
                    value={tuteur.Adresse.CodePostal}
                  />
                  <FormInputText
                    label={"Ville"}
                    name={`Adresse[Ville][]`}
                    onChange={(e) => updateTuteur(tuteurIndex, {
                      ...tuteur,
                      Adresse: {
                        ...tuteur.Adresse,
                        Ville: e.target.value,
                      }
                    })}
                    placeholder={"ex: Paris"}
                    required={true}
                    value={tuteur.Adresse.Ville}
                  />
                </div>
                <FormSelectCountry
                  label={"Pays"}
                  name={`Adresse[Pays][]`}
                  onChange={(e) => updateTuteur(tuteurIndex, {
                    ...tuteur,
                    Adresse: {
                      ...tuteur.Adresse,
                      Pays: e.target.value,
                    }
                  })}
                  placeholder={"Choisissez un pays"}
                  required={true}
                  value={tuteur.Adresse.Pays}
                />
              </FormFieldset>
            </FormFieldset>
          </div>
        ))}
        {localTuteurs.length < 2 && (
          <button
            aria-label={"Ajouter un tuteur"}
            className={"tag-button is-outline"}
            onClick={() => pushEmptyTuteur()}
            title={"Ajouter un tuteur"}
            type={"button"}
          >
            Ajouter un tuteur
            <IconPlus size={"20"}/>
          </button>
        )}
      </div>
      <div className={"helpers-formFooter"}>
        <button
          aria-label={"Continuer"}
          className={"helpers-buttonSubmit tag-button"}
          title={"Continuer"}
          type={"submit"}
        >
          Continuer
        </button>
      </div>
    </form>
  )
}