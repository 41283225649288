export const Etape = {
  Alternance: "Alternance",
  Competences: "Competences",
  Ecole: "Ecole",
  EtatCivil: "EtatCivil",
  ExperiencesProfessionnelles: "ExperiencesProfessionnelles",
  Metiers: "Metiers",
  ParcoursScolaires: "ParcoursScolaires",
  PiecesJointes: "PiecesJointes",
  ProjetProfessionnel: "ProjetProfessionnel",
  SavoirEtreProfessionnel: "SavoirEtreProfessionnel",
  Tuteurs: "Tuteurs",
}

export const SortedEtapes = [
  Etape.EtatCivil,
  Etape.Tuteurs,
  Etape.ParcoursScolaires,
  Etape.ExperiencesProfessionnelles,
  Etape.Metiers,
  Etape.Competences,
  Etape.ProjetProfessionnel,
  Etape.Alternance,
  Etape.Ecole,
  Etape.SavoirEtreProfessionnel,
  Etape.PiecesJointes,
]

export const SituationActuelle = {
  Autre: "Autre",
  DemandeurDemploi: "DemandeurDemploi",
  Etudiant: "Etudiant",
  Salarie: "Salarie",
}

export const Contrat = {
  Alternance: "Alternance",
  CDD: "CDD",
  CDI: "CDI",
  Stage: "Stage",
  VIE: "VIE",
}

export const Statut = {
  EnCours: "EnCours",
  ACorriger: "ACorriger",
  AValider: "AValider",
  NonAdmissible: "NonAdmissible",
  Stopper: "Stopper",
  Valider: "Valider",
}