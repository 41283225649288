import "./FormInputRadioGroup.scss"

import {useRef, useState} from "react"

import FormInputHead from "../../InputHead/FormInputHead"

export default function FormInputRadioGroup(
  {
    children,
    description,
    invalid,
    label,
    name,
    required,
    title,
  }
) {
  const fieldsetRef = useRef(null)
  const [isInvalid, setIsInvalid] = useState(invalid)
  const [validationMessage, setValidationMessage] = useState(null)

  return (
    <fieldset
      className={`FormInput ${isInvalid ? "has-error" : ""}`}
      onInvalid={(e) => {
        setValidationMessage(e.target.validationMessage)
        setIsInvalid(true)
      }}
      onChange={() => {
        setValidationMessage(null)
        setIsInvalid(false)
      }}
      ref={fieldsetRef}
    >
      {label && (
        <FormInputHead
          description={description}
          label={label}
          name={name}
          required={required}
        />
      )}
      {isInvalid && (
        <span className={"FormInput-error"}>
          <p className={"FormInput-errorMessage tag-p is-s"}>{title ? title : validationMessage}</p>
        </span>
      )}
      <div className="FormInputRadioGroup">
        {children}
      </div>
    </fieldset>
  )
}