import "./CandidaturesInitialisationPageSidebar.scss"

import Logo from "../../../Logo/Logo"

export default function CandidaturesInitialisationPageSidebar({onButtonStartClick}) {
  return (
    <div className={"CandidaturesInitialisationPageSidebar"}>
      <div className={"CandidaturesInitialisationPageSidebar-inner"}>
        <Logo size={"l"}/>
        <div className={"CandidaturesInitialisationPageSidebar-titre"}>
          <h1 className={"tag-h1"}>Candidature en ligne</h1>
          <p className={"tag-p is-m"}>
            Renseignez vos informations et envoyez vos documents de manière sécurisée pour
            candidater à HETIC puis sélectionnez votre date pour une <strong>session d’admission</strong>.
          </p>
        </div>
        <div className={"CandidaturesInitialisationPageSidebar-message"}>
          <p className={"tag-p is-m"}>
            INFORMATION : Futurs bacheliers, sachez qu’en parallèle de la procédure Parcoursup, vous pouvez postuler
            directement à notre école.
          </p>
          <p className={"tag-p is-m"}>
            Le dépôt d’un dossier de candidature <strong>n’impacte pas vos voeux d’orientation</strong>, positivement ou
            négativement.
          </p>
          <p className={"tag-p is-m"}>
            Anticipez la poursuite de vos études en postulant dès à présent à l’une de nos sessions d’admission.
          </p>
        </div>
        <div className={"CandidaturesInitialisationPageSidebar-buttonOpenModal"}>
          <button
            aria-label={"Démarrer"}
            className={"tag-button"}
            onClick={() => onButtonStartClick()}
            title={"Démarrer"}
          >
            Démarrer
          </button>
        </div>
      </div>
    </div>
  )
}