import "./CandidaturesSinglePage.scss"

import {useEffect, useState} from "react"
import {useLoaderData} from "react-router-dom"

import {Etape, Statut} from "./constants"
import CandidaturesSinglePageLayout from "./Layout/CandidaturesSinglePageLayout"
import CandidaturesSinglePageEtatCivil from "./EtatCivil/CandidaturesSinglePageEtatCivil"
import CandidaturesSinglePageTuteurs from "./Tuteurs/CandidaturesSinglePageTuteurs"
import {candidatureAllEtapesAreDone, candidatureEtapeIsMade, candidatureSortedEtapes} from "./helpers"
import CandidaturesSinglePageParcoursScolaires from "./ParcoursScolaires/CandidaturesSinglePageParcoursScolaires"
import CandidaturesSinglePageExperiencesProfessionnelles from "./ExperiencesProfessionnelles/CandidaturesSinglePageExperiencesProfessionnelles"
import CandidaturesSinglePageMetiers from "./Metiers/CandidaturesSinglePageMetiers"
import CandidaturesSinglePageCompetences from "./Competences/CandidaturesSinglePageCompetences"
import CandidaturesSinglePageProjetProfessionnel from "./ProjetProfessionnel/CandidaturesSinglePageProjetProfessionnel"
import CandidaturesSinglePageAlternance from "./Alternance/CandidaturesSinglePageAlternance"
import CandidaturesSinglePageEcole from "./Ecole/CandidaturesSinglePageEcole"
import CandidaturesSinglePageSavoirEtreProfessionnel from "./SavoirEtreProfessionnel/CandidaturesSinglePageSavoirEtreProfessionnel"
import CandidaturesSinglePagePiecesJointes from "./PiecesJointes/CandidaturesSinglePagePiecesJointes"
import CandidaturesSinglePageSubmitConfirmation from "./SubmitConfirmation/CandidaturesSinglePageSubmitConfirmation"
import CandidaturesSinglePagePostSoumission from "./PostSoumission/CandidaturesSinglePagePostSoumission"
import {api} from "../services"

export default function CandidaturesSinglePage() {
  const data = useLoaderData()
  const [candidature, setCandidature] = useState(data)
  const [sortedEtapes, setSortedEtapes] = useState([])
  const [currentEtape, setCurrentEtape] = useState(null)
  const [showSubmitConfirmationModal, setShowSubmitConfirmationModal] = useState(false)
  const [showSuccessFileUploadNotification, setShowSuccessFileUploadNotification] = useState(false)

  useEffect(() => {
    setSortedEtapes(
      candidatureSortedEtapes(candidature)
    )
  }, [])

  useEffect(() => {
    for (const etape of sortedEtapes) {
      if (!candidatureEtapeIsMade(candidature, etape)) {
        setCurrentEtape(etape)
        return
      }
    }

    if (candidature.Statut === Statut.EnCours && candidatureAllEtapesAreDone(candidature)) {
      setCurrentEtape(sortedEtapes[sortedEtapes.length - 1])
      return
    }

    if (candidature.Statut === Statut.ACorriger) {
      setCurrentEtape(sortedEtapes[0])
    }
  }, [sortedEtapes])

  useEffect(() => {
    if (showSuccessFileUploadNotification) {
      setTimeout(() => {
        setShowSuccessFileUploadNotification(false)
      }, 2000)
    }
  }, [showSuccessFileUploadNotification])

  const updateEtatCivil = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/etat-civil`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updateTuteurs = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/tuteurs`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updateParcoursScolaires = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/parcours-scolaires`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updateExperiencesProfessionnelles = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/experiences-professionnelles`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updateMetiers = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/metiers`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updateCompetences = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/competences`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updateProjetProfessionnel = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/projet-professionnel`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updateAlternance = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/alternance`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updateEcole = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/ecole`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updateSavoirEtreProfessionnel = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/savoir-etre-professionnel`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updatePiecesJointes = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/pieces-jointes`, data)
      .then(() => {
        setShowSuccessFileUploadNotification(true)
      })
      .then(() => refreshCandidature())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const submitCandidature = () => {
    api("POST", `/candidatures/${candidature.ID}/soumettre`, data)
      .then(() => {
        setShowSubmitConfirmationModal(false)
        setCurrentEtape(null)
      })
      .then(() => refreshCandidature())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const refreshCandidature = () => {
    return new Promise((resolve, reject) => {
      api("GET", `/candidatures/${candidature.ID}`)
        .then(candidature => {
          setCandidature(candidature)
        })
        .then(resolve)
        .catch(reject)
    })
  }

  const moveToNextEtape = () => {
    if (candidatureAllEtapesAreDone(candidature) && currentEtape === sortedEtapes.slice(-1)[0]) {
      setShowSubmitConfirmationModal(true)
      return
    }

    if (currentEtape === sortedEtapes.slice(-1)[0]) {
      return
    }

    const currentEtapeIndex = sortedEtapes.indexOf(currentEtape)
    setCurrentEtape(sortedEtapes[currentEtapeIndex + 1])
  }

  return (
    <>
      {(
        candidature.Statut === Statut.AValider ||
        candidature.Statut === Statut.Stopper ||
        candidature.Statut === Statut.Valider
      ) ? (
        <CandidaturesSinglePagePostSoumission candidature={candidature}/>
      ) : currentEtape ? (
        <CandidaturesSinglePageLayout
          candidature={candidature}
          currentEtape={currentEtape}
          onEtapeChange={setCurrentEtape}
        >
          {currentEtape === Etape.EtatCivil && (
            <CandidaturesSinglePageEtatCivil EtatCivil={candidature.EtatCivil} onEtatCivilChange={updateEtatCivil}/>
          )}
          {currentEtape === Etape.Tuteurs && (
            <CandidaturesSinglePageTuteurs
              EtatCivil={candidature.EtatCivil}
              Tuteurs={candidature.Tuteurs}
              onTuteursChange={updateTuteurs}
            />
          )}
          {currentEtape === Etape.ParcoursScolaires && (
            <CandidaturesSinglePageParcoursScolaires
              ParcoursScolaires={candidature.ParcoursScolaires}
              onParcoursScolairesChange={updateParcoursScolaires}
            />
          )}
          {currentEtape === Etape.ExperiencesProfessionnelles && (
            <CandidaturesSinglePageExperiencesProfessionnelles
              ExperiencesProfessionnelles={candidature.ExperiencesProfessionnelles}
              onExperiencesProfessionnellesChange={updateExperiencesProfessionnelles}
            />
          )}
          {currentEtape === Etape.Metiers && (
            <CandidaturesSinglePageMetiers Metiers={candidature.Metiers} onMetiersChange={updateMetiers}/>
          )}
          {currentEtape === Etape.Competences && (
            <CandidaturesSinglePageCompetences
              Competences={candidature.Competences}
              onCompetencesChange={updateCompetences}
            />
          )}
          {currentEtape === Etape.ProjetProfessionnel && (
            <CandidaturesSinglePageProjetProfessionnel
              ProjetProfessionnel={candidature.ProjetProfessionnel}
              onProjetProfessionnel={updateProjetProfessionnel}
            />
          )}
          {currentEtape === Etape.Alternance && (
            <CandidaturesSinglePageAlternance
              Alternance={candidature.Alternance}
              onAlternanceChange={updateAlternance}
            />
          )}
          {currentEtape === Etape.Ecole && (
            <CandidaturesSinglePageEcole Ecole={candidature.Ecole} onEcoleChange={updateEcole}/>
          )}
          {currentEtape === Etape.SavoirEtreProfessionnel && (
            <CandidaturesSinglePageSavoirEtreProfessionnel
              onSavoirEtreProfessionnelChange={updateSavoirEtreProfessionnel}
              SavoirEtreProfessionnel={candidature.SavoirEtreProfessionnel}
            />
          )}
          {currentEtape === Etape.PiecesJointes && (
            <CandidaturesSinglePagePiecesJointes
              enableSoumettreUneCandidatureButton={candidatureAllEtapesAreDone(candidature)}
              onPieceJointeCategorieSubmit={updatePiecesJointes}
              onValidate={moveToNextEtape}
              PiecesJointes={candidature.PiecesJointes}
              PiecesJointesEtapes={candidature.PiecesJointesEtapes}
            />
          )}

          {showSuccessFileUploadNotification && (
            <div className={"CandidaturesSinglePage-notification"}>
              <p className="tag-p is-extra is-s">Les documents ont été téléchargés avec succès.</p>
            </div>
          )}

          {showSubmitConfirmationModal && (
            <CandidaturesSinglePageSubmitConfirmation
              onClose={() => setShowSubmitConfirmationModal(false)}
              onSubmitCandidature={submitCandidature}
            />
          )}
        </CandidaturesSinglePageLayout>
      ) : ""}
    </>
  )
}