import "./FormInputFile.scss"

import IconEdit from "../../Icon/IconEdit"
import IconPaperClip from "../../Icon/IconPaperClip"
import IconCheck from "../../Icon/IconCheck"

export default function FormInputFile(
  {
    accept,
    invalid,
    multiple,
    name,
    onChange,
    placeholder,
    required,
    textAfterUpload = "Document uploadé",
    title = "Ajouter le document",
    value,
    uploaded,
  }
) {
  return (
    <div className={`FormInputFile ${uploaded ? "is-uploaded" : ""}`}>
      <input
        accept={accept}
        className={`FormInputFile-input ${invalid ? "has-error" : ""}`}
        id={name}
        multiple={multiple}
        name={name}
        onChange={(e) => {
          e.target.classList.remove("has-error")
          onChange(e)
        }}
        onInvalid={e => e.target.classList.add("has-error")}
        placeholder={placeholder}
        required={required}
        type="file"
        value={value}
      />
      <label className={"FormInputFile-label"} htmlFor={name} title={title}>
        <div className={"FormInputFile-labelInner"}>
          {uploaded ? (
            <>
              <IconCheck size={"20"}/>
              {textAfterUpload}
            </>
          ) : (
            <>
              <IconPaperClip size={"20"}/>
              {title}
            </>
          )}
        </div>
        {uploaded && (
          <IconEdit size={"20"}/>
        )}
      </label>
    </div>
  )
}