import "./CandidaturesInitialisationPage.scss"

import {useEffect, useState} from "react"

import CandidaturesInitialisationPageForm from "./Form/CandidaturesInitialisationPageForm"
import CandidaturesInitialisationPageSidebar from "./Sidebar/CandidaturesInitialisationPageSidebar"
import {api} from "../services"

export default function CandidaturesInitialisationPage() {
  const [showingFormOnMobile, setShowingFormOnMobile] = useState(false)
  const [Source, setSource] = useState("")

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setSource(urlParams.get("src"))
  }, [])

  const initialiserUneCandidature = (prospect) => {
    api("POST", "/candidatures", {
      ...prospect,
      Source,
    })
      .then(({ID}) => {
        window.location = `/candidatures/${ID}`
      })
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  return (
    <div className="CandidaturesInitialisationPage">
      <div className="CandidaturesInitialisationPage-sidebar">
        <CandidaturesInitialisationPageSidebar onButtonStartClick={() => setShowingFormOnMobile(true)}/>
      </div>
      <div className={`CandidaturesInitialisationPage-form ${showingFormOnMobile ? "is-open" : ""}`}>
        <CandidaturesInitialisationPageForm
          onButtonCloseClick={() => setShowingFormOnMobile(false)}
          onSubmit={initialiserUneCandidature}
        />
      </div>
    </div>
  )
}