import "./CandidaturesSinglePageExperiencesProfessionnelles.scss"

import {useState} from "react"

import CandidaturesSinglePageExperiencesProfessionnellesForm
  from "./Form/CandidaturesSinglePageExperiencesProfessionnellesForm"
import CandidaturesSinglePageExperiencesProfessionnellesQuestion
  from "./Question/CandidaturesSinglePageExperiencesProfessionnellesQuestion"

export default function CandidaturesSinglePageExperiencesProfessionnelles(
  {
    ExperiencesProfessionnelles,
    onExperiencesProfessionnellesChange,
  }
) {
  const [hasExperiencesProfessionnelles, setHasExperiencesProfessionnelles] = useState(ExperiencesProfessionnelles !== null)

  const onFormExperiencesProfessionnellesChange = (ExperienceProfessionnelle) => {
    if (ExperienceProfessionnelle.length === 0) {
      setHasExperiencesProfessionnelles(false)
      return
    }

    onExperiencesProfessionnellesChange(ExperienceProfessionnelle)
  }

  return (
    <div className={"CandidaturesSinglePageExperiencesProfessionnelles"}>
      <>
        <CandidaturesSinglePageExperiencesProfessionnellesQuestion
          hasExperiencesProfessionnelles={hasExperiencesProfessionnelles}
          onHasExperiencesProfessionnelles={setHasExperiencesProfessionnelles}
        />
        {!hasExperiencesProfessionnelles && (
          <div className={"helpers-formFooter"}>
            <button
              aria-label={"Continuer"}
              className={"helpers-buttonSubmit tag-button"}
              onClick={() => onExperiencesProfessionnellesChange(null)}
              title={"Continuer"}
            >
              Continuer
            </button>
          </div>
        )}
      </>
      {hasExperiencesProfessionnelles && (
        <CandidaturesSinglePageExperiencesProfessionnellesForm
          ExperiencesProfessionnelles={(hasExperiencesProfessionnelles && ExperiencesProfessionnelles) ? ExperiencesProfessionnelles : []}
          onExperiencesProfessionnellesChange={onFormExperiencesProfessionnellesChange}
        />
      )}
    </div>
  )
}