import "./FormFieldset.scss";

export default function FormFieldset({children, label}) {
  return (
    <fieldset className="FormFieldset">
      <span>
        <legend className="FormFieldset-head tag-legend">{label}</legend>
      </span>
      <div className="FormFieldset-body">
        {children}
      </div>
    </fieldset>
  )
}