export const Specialisation = {
  BachelorDataEtIA: "BachelorDataEtIA",
  BachelorDeveloppementWeb: "BachelorDeveloppementWeb",
  BachelorWebmarketingEtUX: "BachelorWebmarketingEtUX",
  MastereCTOEtTechLead: "MastereCTOEtTechLead",
  MastereCybersecurite: "MastereCybersecurite",
  MastereDataEtIA: "MastereDataEtIA",
  MastereMarketingDigitalEtUX: "MastereMarketingDigitalEtUX",
  MastereProductManager: "MastereProductManager",
  PrepaMastereDigital: "PrepaMastereDigital",
  ProgrammeGrandeEcole: "ProgrammeGrandeEcole",
}

export const Months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
]