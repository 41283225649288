import FormInputRadioGroup from "../../../../Form/InputRadio/Group/FormInputRadioGroup"
import FormInputRadio from "../../../../Form/InputRadio/FormInputRadio"

export default function CandidaturesSinglePageExperiencesProfessionnellesQuestion(
  {
    hasExperiencesProfessionnelles,
    onHasExperiencesProfessionnelles,
  }
) {
  return (
    <div className={"CandidaturesSinglePageExperiencesProfessionnellesQuestion"}>
      <div className="helpers-formBody">
        <h2 className={"tag-h2 is-bold"}>
          Avez-vous déjà eu une expérience professionnelle (stage, alternance, interim) dans le secteur d’activité auquel vous souhaitez vous former ?
        </h2>
        <FormInputRadioGroup>
          <FormInputRadio
            checked={hasExperiencesProfessionnelles === true}
            id={"Yes"}
            label={"Oui"}
            name={"hasExperiencesProfessionnelles"}
            onChange={() => onHasExperiencesProfessionnelles(true)}
            required={true}
            value={"1"}
          />
          <FormInputRadio
            checked={hasExperiencesProfessionnelles === false}
            id={"No"}
            label={"Non"}
            name={"hasExperiencesProfessionnelles"}
            onChange={() => onHasExperiencesProfessionnelles(false)}
            required={true}
            value={"0"}
          />
        </FormInputRadioGroup>
      </div>
    </div>
  )
}