import "./CandidaturesSinglePageLayout.scss"

import {useEffect, useState} from "react"

import Logo from "../../../Logo/Logo"
import CandidaturesSinglePageLayoutSidebar from "./Sidebar/CandidaturesSinglePageLayoutSidebar"
import CandidaturesSinglePageLayoutMobileNavigation from "./MobileNavigation/CandidaturesSinglePageLayoutMobileNavigation"
import {getAnneeLabel, getSpecialisationLabel} from "../../helpers"
import {candidatureSessionAsMMMMYYYY} from "../helpers";

export default function CandidaturesSinglePageLayout(
  {
    children,
    candidature,
    currentEtape,
    onEtapeChange,
  }
) {
  const [specialisation, setSpecialisation] = useState("")
  const [annee, setAnnee] = useState("")
  const [session, setSession] = useState("")

  useEffect(() => {
    setSpecialisation(getSpecialisationLabel(candidature.Specialisation))
    setAnnee(getAnneeLabel(candidature.Annee))
    setSession(candidatureSessionAsMMMMYYYY(candidature))
  }, [])

  return (
    <div className={"CandidaturesSinglePageLayout"}>
      <aside className={"CandidaturesSinglePageLayout-sidebar"}>
        <div className={"CandidaturesSinglePageLayout-sidebarHeader"}>
          <Logo size={"m"}/>
        </div>
        <div className={"CandidaturesSinglePageLayout-sidebarContent"}>
          <CandidaturesSinglePageLayoutSidebar
            candidature={candidature}
            currentEtape={currentEtape}
            onEtapeChange={onEtapeChange}
          />
        </div>
      </aside>
      <aside className={"CandidaturesSinglePageLayout-mobileNavigation"}>
        <CandidaturesSinglePageLayoutMobileNavigation
          candidature={candidature}
          currentEtape={currentEtape}
          onEtapeChange={onEtapeChange}
        />
      </aside>
      <div className={"CandidaturesSinglePageLayout-content"}>
        <div className={"CandidaturesSinglePageLayout-contentHeader"}>
          <h1 className={"tag-h2"}>Candidature en ligne</h1>
          <p className={"tag-p is-s"}>
            {`${specialisation} / ${annee} / ${session}`}
          </p>
        </div>
        <div className={"CandidaturesSinglePageLayout-contentForm"}>
          {children}
        </div>
      </div>
    </div>
  )
}