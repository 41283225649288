import {Months} from "../../constants";
import {SortedEtapes} from "../constants";

export const candidatureHasEtape = (
  {
    Etapes,
  },
  etape
) => {
  return etape in Etapes
}

export const candidatureSortedEtapes = (candidature) => {
  return SortedEtapes
    .filter((etape) => candidatureHasEtape(candidature, etape))
    .map((etape) => etape)
}

export const candidatureEtapeIsMade = (
  {
    Etapes,
  },
  etape
) => {
  return Etapes[etape] === true
}

export const candidatureProgressionPercent = (
  {
    Etapes,
  }
) => {
  let etapesCount = 0
  let etapesMade = 0

  for (const key in Etapes) {
    etapesCount += 1
    if (Etapes[key] === true) {
      etapesMade += 1
    }
  }

  return Math.ceil(etapesMade * 100 / etapesCount)
}

export const candidatureSessionAsMMMMYYYY = (
  {
    Session,
  }
) => {
  const month = Session.split("/")[0]
  const year = Session.split("/")[1]
  return `${Months[month - 1]} ${year}`
}

export const candidatureAllEtapesAreDone = (
  {
    Etapes,
  }
) => {
  for (const etape in Etapes) {
    if (!Etapes[etape]) {
      return false
    }
  }
  return true
}