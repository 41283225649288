export default function IconTrash({size}) {
  return (
    <svg
      fill="none"
      width={size ? size : "24"}
      height={size ? size : "24"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="m11.1615.999999h1.677c.5272-.000018.982-.000033 1.3565.030571.3954.0323.7886.10362 1.167.29641.5645.28762 1.0234.74656 1.311 1.31105.1928.37838.2641.7716.2964 1.16694.0275.33578.0303.736.0306 1.19503h4c.5523 0 1 .44772 1 1s-.4477 1-1 1h-1v10.2413c0 .805 0 1.4693-.0442 2.0105-.0459.5621-.1445 1.0788-.3918 1.5642-.3835.7526-.9954 1.3645-1.748 1.748-.4854.2473-1.0021.3459-1.5642.3918-.5412.0442-1.2055.0442-2.0105.0442h-4.48262c-.80496 0-1.46932 0-2.01051-.0442-.56209-.0459-1.0788-.1445-1.56413-.3918-.75265-.3835-1.36457-.9954-1.74807-1.748-.24729-.4854-.34585-1.0021-.39178-1.5642-.04421-.5412-.0442-1.2055-.04419-2.0105v-10.2413h-1c-.55228 0-1-.44772-1-1s.44772-1 1-1h4.00003c.00028-.45903.00311-.85925.03054-1.19503.0323-.39534.10362-.78856.29641-1.16694.28762-.56449.74656-1.02343 1.31105-1.31105.37838-.19279.7716-.26411 1.16694-.29641.37453-.030604.82933-.030589 1.35653-.030571zm-5.1615 6.000001v10.2c0 .8566.00078 1.4389.03755 1.889.03582.4384.10075.6626.18044.819.19174.3763.4977.6823.87403.874.1564.0797.3806.1446.81902.1804.45009.0368 1.03238.0376 1.88896.0376h4.4c.8566 0 1.4389-.0008 1.889-.0376.4384-.0358.6626-.1007.819-.1804.3763-.1917.6823-.4977.874-.874.0797-.1564.1446-.3806.1804-.819.0368-.4501.0376-1.0324.0376-1.889v-10.2zm8.9999-2h-5.99983c.00044-.4651.0035-.78302.02386-1.03216.02219-.27167.05987-.37239.08506-.42183.09588-.18816.24886-.34114.43702-.43702.04944-.02519.15016-.06287.42183-.08506.28336-.02315.65556-.02393 1.23216-.02393h1.6c.5766 0 .9488.00078 1.2322.02393.2716.02219.3723.05987.4218.08506.1882.09588.3411.24886.437.43702.0252.04944.0629.15016.0851.42183.0203.24914.0234.56706.0238 1.03216zm-4.9999 5.5c.5523 0 1 .4477 1 1v5c0 .5523-.4477 1-1 1-.55228 0-1-.4477-1-1v-5c0-.5523.44772-1 1-1zm4 0c.5523 0 1 .4477 1 1v5c0 .5523-.4477 1-1 1s-1-.4477-1-1v-5c0-.5523.4477-1 1-1z"
            fill="currentColor"/>
    </svg>
  )
}