import "./CandidaturesSinglePageMetiers.scss"

import {useEffect, useState} from "react"

import FormTextarea from "../../../Form/FormTextarea"
import FormInputText from "../../../Form/FormInputText"
import {scrollToFirstError} from "../../helpers"

export default function CandidaturesSinglePageMetiers({Metiers, onMetiersChange}) {
  const [
    CommentJaiFaitDesRecherchesAfinDeDeterminerLaFormationQueJeSouhaiteSuivre,
    setCommentJaiFaitDesRecherchesAfinDeDeterminerLaFormationQueJeSouhaiteSuivre,
  ] = useState(Metiers.CommentJaiFaitDesRecherchesAfinDeDeterminerLaFormationQueJeSouhaiteSuivre)
  const [
    MetiersAuxquelsCetteFormationPermetDacceder,
    setMetiersAuxquelsCetteFormationPermetDacceder,
  ] = useState(Metiers.MetiersAuxquelsCetteFormationPermetDacceder ?? [])

  const updateMetiers = (index, value) => {
    const metiersCopy = [...MetiersAuxquelsCetteFormationPermetDacceder]
    metiersCopy[index] = value
    setMetiersAuxquelsCetteFormationPermetDacceder(metiersCopy)
  }

  const submit = (event) => {
    event.preventDefault()

    if(!event.target.checkValidity()) {
      scrollToFirstError(event.target)
      return
    }

    const metiersWithoutEmptyValues = MetiersAuxquelsCetteFormationPermetDacceder.filter(item => item.trim() !== "")

    onMetiersChange({
      CommentJaiFaitDesRecherchesAfinDeDeterminerLaFormationQueJeSouhaiteSuivre,
      MetiersAuxquelsCetteFormationPermetDacceder: metiersWithoutEmptyValues,
    })
  }

  return (
    <form className={"CandidaturesSinglePageMetiers"} onSubmit={submit} noValidate>
      <div className={"helpers-formBody"}>
        <FormTextarea
          description={"Conseiller d’orientation, fiche métier, rencontres, forum métier, autres..."}
          label={
            <>
              Avez-vous fait des recherches afin de déterminer la formation que vous souhaitez suivre ?
              <span className={"helpers-ifYes"}>
                Si oui, comment ?
              </span>
            </>
          }
          name={"CommentJaiFaisDesRecherchesAfinDeDeterminerLaFormationQueJeSouhaiteSuivre"}
          onChange={(e) => setCommentJaiFaitDesRecherchesAfinDeDeterminerLaFormationQueJeSouhaiteSuivre(e.target.value)}
          value={CommentJaiFaitDesRecherchesAfinDeDeterminerLaFormationQueJeSouhaiteSuivre}
        />
        <div>
          <div className="tag-label">
            <p>
              Pouvez-vous citer quelques métiers auxquels cette formation permet d'accéder ?
              <span className={"helpers-ifYes"}>
                Si oui, citez les ci-dessous
              </span>
            </p>
          </div>
          <div className={"CandidaturesSinglePageMetiers-metiersAfterFormationInputs"}>
            {Array.from({length: 3}, (_, index) => (
              <FormInputText
                key={index}
                name={"MetiersAuxquelsCetteFormationPermetDacceder[]"}
                onChange={(e) => updateMetiers(index, e.target.value)}
                value={MetiersAuxquelsCetteFormationPermetDacceder?.[index] ?? ""}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={"helpers-formFooter"}>
        <button
          aria-label={"Continuer"}
          className={"helpers-buttonSubmit tag-button"}
          type={"submit"}
          title={"Continuer"}
        >
          Continuer
        </button>
      </div>
    </form>
  )
}