import "./FormInput.scss"

import {useState} from "react"

import FormInputHead from "./InputHead/FormInputHead"

export default function FormSelect(
  {
    description,
    disabled,
    invalid,
    label,
    name,
    onChange,
    options = {},
    placeholder,
    required = false,
    title,
    value,
  }
) {
  const [isInvalid, setIsInvalid] = useState(invalid)
  const [validationMessage, setValidationMessage] = useState(null)

  return (
    <div className={`FormInput ${disabled ? "is-disabled" : ""}`}>
      <FormInputHead
        description={description}
        label={label}
        name={name}
        required={required}
      />
      {isInvalid && (
        <span className={"FormInput-error"}>
          <p className={"FormInput-errorMessage tag-p is-s"}>{title ? title : validationMessage}</p>
        </span>
      )}
      <select
        className={`tag-select ${isInvalid ? "has-error" : ""}`}
        disabled={disabled}
        id={name}
        name={name}
        onChange={e => {
          setValidationMessage(null)
          setIsInvalid(false)
          onChange(e)
        }}
        onInvalid={(e) => {
          setValidationMessage(e.target.validationMessage)
          setIsInvalid(true)
        }}
        value={value}
        required={required}
      >
        {placeholder && (
          <option value={""} disabled>{placeholder}</option>
        )}
        {Object.keys(options).map((option, i) => (
          <option key={i} value={option}>{options[option]}</option>
        ))}
      </select>
    </div>
  )
}