export default function IconPaperClip({size}) {
  return (
    <svg
      fill="none"
      width={size ? size : "24"}
      height={size ? size : "24"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path clipRule="evenodd"
            d="M17.9705 4.18198C16.9942 3.20567 15.4113 3.20567 14.435 4.18198L5.41938 13.1976C3.75965 14.8573 3.75965 17.5483 5.41938 19.208C7.07911 20.8677 9.77006 20.8677 11.4298 19.208L20.4454 10.1924C20.8359 9.80186 21.4691 9.80186 21.8596 10.1924C22.2501 10.5829 22.2501 11.2161 21.8596 11.6066L12.844 20.6222C10.4032 23.063 6.44594 23.063 4.00517 20.6222C1.56439 18.1814 1.56439 14.2242 4.00517 11.7834L13.0208 2.76777C14.7781 1.01041 17.6274 1.01041 19.3847 2.76777C21.1421 4.52513 21.1421 7.37437 19.3847 9.13173L10.7227 17.7938C9.64874 18.8677 7.90753 18.8677 6.83359 17.7938C5.75965 16.7198 5.75965 14.9786 6.83359 13.9047L14.435 6.3033C14.8255 5.91278 15.4587 5.91278 15.8492 6.3033C16.2397 6.69382 16.2397 7.32699 15.8492 7.71751L8.24781 15.3189C7.95491 15.6118 7.95491 16.0867 8.24781 16.3796C8.5407 16.6725 9.01557 16.6725 9.30847 16.3796L17.9705 7.71751C18.9468 6.7412 18.9468 5.15829 17.9705 4.18198Z"
            fill="currentColor" fillRule="evenodd"/>
    </svg>
  )
}