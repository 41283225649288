export const getAgeFromBirthDate = (birthDate) => {
  const birthDateParts = birthDate.split("/")
  const birthDateObj = new Date(birthDateParts[2], birthDateParts[1] - 1, birthDateParts[0])
  const today = new Date()

  let age = today.getFullYear() - birthDateObj.getFullYear()
  const monthsDifferenceBetweenMonthOfBirthAndTodayMonth = today.getMonth() - birthDateObj.getMonth()

  if (
    monthsDifferenceBetweenMonthOfBirthAndTodayMonth < 0
    || (monthsDifferenceBetweenMonthOfBirthAndTodayMonth === 0 && today.getDate() < birthDateObj.getDate())
  ) {
    age--
  }

  return age
}