import "./FormInputRadio.scss"

export default function FormInputRadio(
  {
    checked,
    id,
    label,
    name,
    onChange,
    required,
    value,
  }
) {
  return (
    <label className="FormInputRadio" htmlFor={id}>
      <input
        checked={checked}
        id={id}
        name={name}
        onChange={onChange}
        required={required}
        type={"radio"}
        value={value}
      />
      <span className="FormInputRadio-inner">
        <span className={"FormInputRadio-text"}>
          {label}
        </span>
      </span>
    </label>
  )
}