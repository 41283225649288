import "./CandidaturesSinglePageLayoutSidebar.scss"

import classNames from "classnames"

import IconLock from "../../../../Icon/IconLock"
import {getEtapeLabel} from "../../../helpers";
import {candidatureEtapeIsMade, candidatureSortedEtapes} from "../../helpers"

export default function CandidaturesSinglePageLayoutSidebar(
  {
    candidature,
    currentEtape,
    onEtapeChange,
  }
) {
  const sortedEtapes = candidatureSortedEtapes(candidature)

  return (
    <nav className={"CandidaturesSinglePageLayoutSidebar"}>
      <ul className={"CandidaturesSinglePageLayoutSidebar-etapesList"}>
        {sortedEtapes.map((etape, key) => {
          const etapeIsUnlocked = key === 0 || candidatureEtapeIsMade(candidature, sortedEtapes[key - 1])
          return (
            <li key={etape}>
              <button
                aria-label={getEtapeLabel(etape)}
                className={classNames("CandidaturesSinglePageLayoutSidebar-button tag-p is-m is-extra", {
                  "is-current": currentEtape === etape,
                  "is-passed": candidatureEtapeIsMade(candidature, etape),
                  "is-unlocked": etapeIsUnlocked,
                })}
                disabled={!(etapeIsUnlocked || candidatureEtapeIsMade(candidature, etape))}
                onClick={() => onEtapeChange(etape)}
                title={getEtapeLabel(etape)}
              >
                  <span className={"CandidaturesSinglePageLayoutSidebar-buttonBefore"}>
                    <span className={"CandidaturesSinglePageLayoutSidebar-buttonLockedIcon"}>
                      <IconLock size={"20px"}/>
                    </span>
                  </span>
                {getEtapeLabel(etape)}
              </button>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}