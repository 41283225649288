import React from "react"
import {createBrowserRouter, RouterProvider} from "react-router-dom"

import ErrorElement from "./ErrorElement"
import CandidaturesInitialisationPage from "../Candidatures/InitialisationPage/CandidaturesInitialisationPage"
import CandidaturesSinglePage from "../Candidatures/SinglePage/CandidaturesSinglePage"
import {api} from "../Candidatures/services"

export default function Router() {
  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: <ErrorElement/>,
      children: [
        {
          path: "/",
          element: <CandidaturesInitialisationPage/>,
        },
        {
          path: "/candidatures/:id",
          element: <CandidaturesSinglePage/>,
          loader: ({params}) => api("GET", `/candidatures/${params.id}`)
        }
      ]
    }
  ])

  return (
    <RouterProvider router={router}/>
  )
}